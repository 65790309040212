<template>
  <div>
    <v-btn @click="getUser">click</v-btn>
  </div>
</template>

<script>
export default {
  methods: {
    getUser() {
      this.axios.get("http://localhost/api/user").then(response => {
        console.log(response.data);
      });
    }
  }
};
</script>

<style></style>
